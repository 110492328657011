@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    /* font-family: Montserrat, sans-serif; */
    font-family: 'Mulish', sans-serif;
  }
}
@layer components {
  .header {
    @apply fixed top-0 z-50 flex w-full items-center justify-between px-4 py-4 transition-all lg:px-10 lg:py-6;
  }
  .headerLink {
    @apply cursor-not-allowed text-sm font-light text-[#e5e5e5] transition duration-300 hover:text-[#b3b3b3];
  }
  .bannerButton {
    @apply cursor-pointer flex items-center gap-x-2 rounded px-5 py-1.5 text-sm font-semibold transition hover:opacity-75 md:py-2.5 md:px-8 md:text-xl;
  }
  .modalButton {
    @apply flex h-11 w-11 items-center justify-center rounded-full border-2 border-[gray] bg-[#2a2a2a]/60 transition hover:border-white hover:bg-white/10;
  }
  .input {
    @apply w-full rounded bg-[#333] px-5 py-3.5 placeholder-[gray] outline-none focus:bg-[#454545];
  }
  .planBox {
    @apply relative mx-1.5 flex items-center justify-center h-20 bg-[#e50914] w-[calc(100%/3)] cursor-default rounded-sm font-semibold shadow after:absolute after:top-full after:left-1/2 after:block after:-translate-x-1/2 after:border-8 after:border-b-0 after:border-transparent after:border-t-[#e50914] md:h-32 lg:mx-8;
  }
  .tableRow {
    @apply flex flex-wrap items-center font-medium;
  }
  .tableDataTitle {
    @apply w-full p-2.5 text-center text-sm font-normal text-white md:w-2/5 md:p-3.5 md:text-left md:text-base;
  }
  .tableDataFeature {
    @apply w-[calc(100%/3)] p-2.5 text-center md:w-[calc(60%/3)] md:p-3.5;
  }
  .membershipLink {
    @apply cursor-not-allowed text-blue-500 hover:underline;
  }

  .mainMenu {
    @apply md:hidden;
  }

  .mainMenu .MuiPaper-root {
    @apply !absolute !left-0 !rounded-none !border !border-[gray] !bg-black !text-white;
  }

  .mainMenu .MuiList-root {
    @apply !p-0;
  }

  .mainMenu .MuiMenuItem-root {
    @apply !block !w-72 !py-3.5 !text-center !text-sm !font-light !text-[#b3b3b3] !transition !duration-200 first:cursor-default first:!font-normal first:!text-white hover:!bg-[#11100F];
  }
  .ar model-viewer{
    width: 100%;
    height: 100%;
  }

}
@responsive {
  .text-shadow {
    text-shadow: 0 1px 3px rgba(0,0,0,1);
  }

  .text-shadow-md {
    text-shadow: 0 4px 8px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.08);
  }

  .text-shadow-lg {
    text-shadow: 0 15px 30px rgba(0,0,0,0.11), 0 5px 15px rgba(0,0,0,0.08);
  }

  .text-shadow-none {
    text-shadow: none;
  }
}

.swiper-pagination-bullet-active {
  background-color: #fff !important;
}
.swiper-pagination-bullet{
  background-color: #fff;
}

#renderCanvas,#my-canvas {
  width   : 100%;
  height  : 100vh;
  touch-action: none;
}



#modelViewer {
  background-color: #455A64;
  overflow-x: hidden;
  --poster-color: #eee;
  width: 100%;
  height: 480px;
  /* border: 10px solid #1f2530; */
}

#modelViewer #ar-button {
  background-image: url(https://modelviewer.dev/assets/ic_view_in_ar_new_googblue_48dp.png);
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 12px 50%;
  background-color: #fff;
  position: absolute;
  transform: translateX(-50%);
  white-space: nowrap;
  padding: 0px 16px 0px 40px;
  font-family: Roboto Regular, Helvetica Neue, sans-serif;
  font-size: 14px;
  color:#4285f4;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  border: 1px solid #DADCE0;
  /* top: 120px; 
  right: 5px;  */
  bottom: 5px;
  left: 25%;
}

#ar-button:active {
  background-color: #E8EAED;
}

#ar-button:focus {
  outline: none;
}

#ar-button:focus-visible {
  outline: 1px solid #4285f4;
}

@keyframes circle {
  from { transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg); }
  to   { transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg); }
}

@keyframes elongate {
  from { transform: translateX(100px); }
  to   { transform: translateX(-100px); }
}

#modelViewer > #ar-prompt {
  position: absolute;
  left: 50%;
  bottom: 175px;
  animation: elongate 2s infinite ease-in-out alternate;
  display: none;
}

#modelViewer[ar-status="session-started"] > #ar-prompt {
  display: block;
}

#modelViewer > #ar-prompt > img {
  animation: circle 4s linear infinite;
}

#modelViewer > #ar-failure {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 175px;
  display: none;
}

#modelViewer[ar-tracking="not-tracking"] > #ar-failure {
  display: block;
}