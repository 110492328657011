#header , #img_header{

  &::before{
    content: '';
    width: 100%;
    height: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 60%, rgba(0,0,0,1) 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-blend-mode: multiply;
    visibility: hidden;
  }
  .logo{
    position: absolute;
    top: 0;
    color:white;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    padding:25px;
    letter-spacing: 2px;
    img{
      width: 137px;
      image-rendering: -moz-crisp-edges;         /* Firefox */
      image-rendering:   -o-crisp-edges;         /* Opera */
      image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
    }
    span{
      text-transform:initial;
      font-weight: 200;
      font-size: 15px;
      margin-left: 10px;
    }
  }
  .caption{
    color:white;
    z-index: 2;
    .title{
      font-size: 2.5rem;
      font-weight: bold;
      letter-spacing: 2px;
      text-transform: capitalize;
    }
    p{
      font-weight: 100;
      font-size: 8pt;
      line-height: 1.2;
      letter-spacing: 2px;
    }
    .header_playbtn{
      outline: none;
      background: none;
      border:1px solid #fff;
      text-transform: uppercase;
      font-size: 8pt;
      letter-spacing: 1px;
      color:var(--white);
      padding:10px 30px;
      border-radius: 50px;
      margin-top: 15px;
      cursor: pointer;
      transition: 0.3s;
      &:hover{
        background:rgba(255, 255, 255, 0.2);
      }
    }
  }
}

.scroll-down{
  border: 2px solid #fff;
  border-radius: 20px;
  bottom: 25px;
  height: 50px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 30px;
  &::before{
    animation: scrollDownAnimation 2s infinite;
    background-color: #fff;
    border-radius: 100%;
    content: '';
    height: 6px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 10px;
    width: 6px;
  }
}
@keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}
.fp-watermark{
  display: none;
}

#fp-nav ul li a span, 
.fp-slidesNav ul li a span{
  background: #cccccc !important;
}